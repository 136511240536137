<script>
import { authMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import { userService } from "@/services/user.service";

export default {
  data() {
    return {
      user: {
        email: this.$route.params.email,
        token: "",
      },
      resendTokenLoading: false,
      loading: false,
      errors: {},
      submitted: false,
      email: "",
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  validations: {
    user: {
      token: { required },
    },
  },
  created() {
    if (this.$route.params.email == null) {
      this.$router.push("/login");
    }

    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    async tryToVerifyEmail() {
      this.$store.dispatch("notification/clear");
      this.errors = {};
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        // verify email logic
        this.loading = true;
        try {
          var response = await userService.verifyAccount(this.user);
          this.loading = false;
          this.$store.dispatch("notification/success", response.data.message);
          this.$router.push("/login");
          console.log(response);
        } catch (error) {
          this.loading = false;

          switch (error.response.status) {
            case 422:
              console.log(error.response.data.errors);
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$store.dispatch(
                "notification/error",
                error.response.data.message
              );
              break;
            default:
              this.$store.dispatch(
                "notification/error",
                "Something went wrong. Please try again!"
              );
              break;
          }
        }
      }
    },

    async resendToken() {
      this.errors = {};
      this.resendTokenLoading = true;
      this.submitted = false;

      try {
        var response = await userService.resendToken(this.user);
        this.resendTokenLoading = false;
        this.$store.dispatch("notification/success", response.data.message);
        // console.log(response);
        // this.$router.push("/login");
      } catch (error) {
        this.resendTokenLoading = false;
        console.log(error);
        switch (error.response.status) {
          case 422:
            console.log(error.response.data.errors);
            this.errors = error.response.data.errors;
            break;
          case 500:
            this.$store.dispatch(
              "notification/error",
              error.response.data.message
            );
            break;
          default:
            this.$store.dispatch(
              "notification/error",
              "Something went wrong. Please try again!"
            );
            break;
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              src="@/assets/images/logo-dark.png"
                              height="20"
                              alt="logo"
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Verify Email</h4>
                        <p class="text-muted">
                          Enter the token we sent to [<b>{{
                            $route.params.email
                          }}</b
                          >] address and your account would be verified.
                        </p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          :variant="notification.type"
                          class="mt-1"
                          v-if="notification.message"
                          show
                          dismissible
                          @dismissed="$store.dispatch('notification/clear')"
                          >{{ notification.message }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToVerifyEmail"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="token">Token</label>
                            <input
                              v-model="user.token"
                              type="number"
                              class="form-control"
                              id="token"
                              placeholder="Enter Token"
                              :class="{
                                'is-invalid':
                                  (submitted && $v.user.token.$error) ||
                                  errors.token ||
                                  errors.email,
                              }"
                              autofocus
                            />
                            <div
                              v-if="submitted && $v.user.token.$error"
                              class="invalid-feedback"
                            >
                              Token is required.
                            </div>
                            <div class="invalid-feedback" v-if="errors">
                              <span v-if="errors.token"
                                >{{ errors.token[0] }} &nbsp;</span
                              >
                              <span v-if="errors.email">{{
                                errors.email[0]
                              }}</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              Verify Account
                              <b-spinner
                                v-show="loading"
                                small
                                class="align-middle"
                              ></b-spinner>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Didn't receive the token ?
                          <a
                            href="javascript:void(0)"
                            @click="resendToken()"
                            class="font-weight-medium"
                          >
                            Resend
                            <b-spinner
                              v-show="resendTokenLoading"
                              small
                              class="align-middle"
                            ></b-spinner>
                          </a>
                          <!-- <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Login</router-link
                          > -->
                        </p>
                        <p>
                          Already verified your account ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Login</router-link
                          >
                        </p>
                        <p>
                          © 2020 Cadlands. Crafted with
                          <i class="mdi mdi-heart text-danger"></i> by Encodeup
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>